import { getAnonymousId } from "./analytics";
import { handleResponseError } from "./error-utils/response-error-handlers";

const defaultOptions: Record<string, Record<string, string> | string> = {
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
    "X-Anonymous-Id": getAnonymousId(),
  },
};

export async function fetchApi<T>(url: string, params: Record<string, unknown> = {}): Promise<T> {
  const requestOptions: RequestInit = { ...defaultOptions, ...params };

  return fetch(url, requestOptions).then(async (res: Response): Promise<T> => {
    if (!res.ok) {
      await handleResponseError(res);
    }

    return res as unknown as T;
  });
}

export async function fetchApiJson<T>(
  url: string,
  params: Record<string, unknown> = {}
): Promise<T> {
  const requestOptions: RequestInit = { ...defaultOptions, ...params };

  return fetch(url, requestOptions).then(async (res: Response): Promise<T> => {
    if (!res.ok) {
      await handleResponseError(res);
    }

    return res.json();
  });
}
