import { graphql } from "gatsby";
import { ReactElement } from "react";

import PreSignature from "../../../features/signatures/ui/pages/PreSignature";

interface PreSignaturePageProps {
  params: {
    quoteUuid: string;
  };
}

const PreSignaturePage = ({ params }: PreSignaturePageProps): ReactElement => (
  <PreSignature quoteUuid={params.quoteUuid} />
);

export default PreSignaturePage;

export const query = graphql`
  query PreSignaturePageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
